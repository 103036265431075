import React, { useEffect, useState } from "react"
import { Box, Button, Heading } from "grommet"
import {
  Layout,
  SEO,
  FormattedMessage,
  Connection,
  LoadingWithText,
  EmptyData,
  Info,
  Spinner,
  toast,
  Tips,
  AppIcon,
} from "../components"
import { useIntl, useAuth0, useApi } from "../hooks"
import { Add, Share } from "grommet-icons"
import {
  redirect,
  reportError,
  fetchApi,
  encodeAppState,
  authWithPopup,
} from "../utils"
const Page = ({
  pageContext: {
    app: { provider, name, picture, url, docs_url },
  },
}) => {
  const intl = useIntl()
  const { formatMessage } = intl
  const auth0 = useAuth0()
  const { isAuthenticated, loading } = auth0
  const [createdLoading, setCreatedLoading] = useState(false)

  let {
    data: connectionsRawData,
    isValidating: connectionsLoading,
    error,
  } = useApi(
    isAuthenticated && loading === false
      ? `/apps/${provider}/connections`
      : null
  )
  const [connectionsData, setConnectionsData] = useState()
  useEffect(() => {
    if (error) {
      reportError(error)
    }
    if (connectionsRawData) {
      setConnectionsData(connectionsRawData.data.items)
    }
  }, [connectionsRawData, error])
  const handleCreateConnection = async () => {
    setCreatedLoading(true)
    try {
      const createUrl = `/authenticated-url/${provider}?app_state=${encodeAppState(
        {
          action: "create",
        }
      )}`
      const authenticatedUrlData = await fetchApi(createUrl, {
        autoLogin: true,
      })
      setCreatedLoading(false)
      if (
        authenticatedUrlData &&
        authenticatedUrlData.data &&
        authenticatedUrlData.data.location
      ) {
        try {
          const authResult = await authWithPopup({
            url: authenticatedUrlData.data.location,
          })
          console.log("authResult", authResult)

          //redirect(authenticatedUrlData.data.location)
        } catch (error) {
          console.log("error", error)
        }
      }
    } catch (error) {
      setCreatedLoading(false)
      toast(error.message)
      reportError(error)
    }
  }
  const handleRemoveConnection = ({ index } = {}) => {
    if (typeof index !== "undefined") {
      setConnectionsData(
        connectionsData.filter((_, itemIndex) => {
          if (itemIndex === index) {
            return false
          } else {
            return true
          }
        })
      )
      toast.success(
        formatMessage({
          id: "disconnect_success",
          defaultMessage: "The connection has been disconnected",
        })
      )
    }
  }

  const isHasItems = connectionsData && connectionsData.length > 0

  return (
    <Layout>
      <SEO title="Home" />
      <Box pad={{ top: "medium" }}>
        <Box pad={{ bottom: "xlarge" }} gap="small" align="center">
          <AppIcon size="large" picture={picture} provider={provider}></AppIcon>
          <Heading level="1">{name}</Heading>
          <Box gap="medium" direction="row">
            <Button
              reverse
              target="_blank"
              href={url}
              secondary
              icon={<Share size="small"></Share>}
              label={intl.formatMessage(
                {
                  id: "visit_app",
                  defaultMessage: "Visit {name}",
                },
                {
                  name,
                }
              )}
            ></Button>
            <Button
              reverse
              secondary
              target="_blank"
              href={docs_url}
              icon={<Share size="small"></Share>}
              label={intl.formatMessage({
                id: "official_docs",
                defaultMessage: "Official Docs",
              })}
            ></Button>
          </Box>
        </Box>

        {isHasItems && (
          <Box direction="row">
            <Button
              primary
              size="large"
              disabled={createdLoading}
              icon={createdLoading ? <Spinner /> : <Add></Add>}
              onClick={handleCreateConnection}
              label={intl.formatMessage({
                id: "create_new_connection",
                defaultMessage: "Create new connection",
              })}
            ></Button>
          </Box>
        )}

        <Box>
          {isHasItems && (
            <Heading>
              <FormattedMessage
                id="connections"
                defaultMessage="Connections"
              ></FormattedMessage>
            </Heading>
          )}

          <Box overflow="visible">
            {(loading || (connectionsLoading && !connectionsData)) && (
              <LoadingWithText
                text={intl.formatMessage({
                  id: "loading_connections",
                  defaultMessage: "Loading your connections...",
                })}
              ></LoadingWithText>
            )}
            {isAuthenticated === false && loading === false && (
              <Info
                title={intl.formatMessage(
                  {
                    id: "want_to_connect_app",
                    defaultMessage: "Want to connect {name}?",
                  },
                  {
                    name: name,
                  }
                )}
                text={intl.formatMessage(
                  {
                    id: "login_to_connect_app",
                    defaultMessage: "Login to connect {name}",
                  },
                  {
                    name: name,
                  }
                )}
                button={{
                  label: intl.formatMessage({
                    id: "login_or_signup",
                    defaultMessage: "Login or Signup",
                  }),
                  disabled: createdLoading,
                  onClick: handleCreateConnection,
                  primary: true,
                }}
              ></Info>
            )}
            {connectionsData && connectionsData.length === 0 && (
              <EmptyData
                title={intl.formatMessage({
                  id: "no_connections_yet",
                  defaultMessage: "No connections yet",
                })}
                text={intl.formatMessage(
                  {
                    id: "you_can_add_your_first_app_connection_now",
                    defaultMessage:
                      "You can add your first {name} connection now!",
                  },
                  {
                    name: name,
                  }
                )}
                button={{
                  label: intl.formatMessage(
                    {
                      id: "connect_to_app",
                      defaultMessage: "Connect to {name}",
                    },
                    { name: name }
                  ),
                  disabled: createdLoading,
                  icon: createdLoading ? <Spinner /> : <Add></Add>,
                  onClick: handleCreateConnection,
                  primary: true,
                }}
              ></EmptyData>
            )}
            {isHasItems &&
              connectionsData.map((item, index) => (
                <Connection
                  onRemove={handleRemoveConnection}
                  index={index}
                  isShowIcon={false}
                  key={item.connectionId}
                  {...item}
                />
              ))}
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default Page
